import React from "react";
import darkLogo from "../../images/dark_logo512.png";
import lightLogo from "../../images/light_logo512.png";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import ThemeModeContext from "../../store/theme-context";
import { Link } from "react-router-dom";

export default function Title() {
  const themeModeCtx = useContext(ThemeModeContext);
  return (
    <Box component={Link} to="/" sx={{ display: "flex", alignItems: "center", p: 1, textDecoration: "none", color: "text.primary" }}>
      <Box component="img" src={themeModeCtx.darkMode ? lightLogo : darkLogo} sx={{ height: { xs: "52px", md: "64px" } }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: -3 }}>
        <Typography
          sx={{
            fontFamily: "montserrat",
            fontWeight: 500,
            fontSize: { xs: "0.8rem", md: "2.5rem" },
            ml: { xs: 0.5, md: 2 },
            mb: { sx: 0, md: -2 },
          }}
        >
          CYKELJUSTERINGEN
        </Typography>
        <Typography
          color={themeModeCtx.darkMode ? "primary.light" : "primary.dark"}
          sx={{ fontFamily: "montserrat", fontWeight: 500, fontSize: { xs: "0.6rem", md: "1.2rem" }, ml: { xs: 0.5, md: 2 } }}
        >
          MANAGERSPIL FOR CYKELNØRDER
        </Typography>
      </Box>
      {/* <Typography variant="h3" sx={{ fontFamily: "Rubik Glitch", textAlign: "center", fontSize: { xs: "1.5rem", sm: "3rem" }, ml: 2 }}>
        CykelJusteringen
      </Typography> */}
    </Box>
  );
}
