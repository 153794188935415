import React, { useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Box, IconButton } from "@mui/material";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";

import Footer from "./components/UI/Footer";
import ThemeModeContext from "./store/theme-context";

import { darkThemeOptions, lightThemeOptions } from "./themeOptions";
import { Toaster } from "react-hot-toast";
import TopBar from "./components/UI/TopBar";
import useCheckForStoredToken from "./hooks/useCheckForStoredToken";
import PostHogPageView from "./pageview";

export default function App() {
  useCheckForStoredToken();
  const themeModeCtx = useContext(ThemeModeContext);

  const darkTheme = createTheme(darkThemeOptions);
  const lightTheme = createTheme(lightThemeOptions);

  useEffect(() => {
    // const localLightModePref = localStorage.getItem("lightModePref");
    // if (localLightModePref === "true") {
    //   themeModeCtx.setLightMode();
    // } else {
    //   themeModeCtx.setDarkMode();
    // }
    themeModeCtx.setDarkMode();
  }, []);

  return (
    <ThemeProvider theme={themeModeCtx.darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <div id="app" className="flex min-h-screen flex-col">
        <TopBar />
        {/* <IconButton variant="text" color="inherit" onClick={themeModeCtx.toggleDarkMode} sx={{ position: "fixed", right: 0, bottom: 0, zIndex: 5000 }}>
          {themeModeCtx.darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton> */}
        <Outlet />
        <PostHogPageView />
        <Toaster />
        <Footer />
      </div>
    </ThemeProvider>
  );
}
